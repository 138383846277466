(function($) {
    'use strict';

    window.HomepageBg = function(imageSource) {
        this.wrapper = '.homepage-sc';
        this.imageWrapper = '.homepage-sc-img';
        this.imgSource = imageSource;
        this.logo_intro_animate;
        this.logo_intro_animate_state=true;



        this.init = function() {

            var self = this;

            progressJs().setOption("theme", "black").start();

            $(document).click(function(e) {
                //self.requestFullScreen();
                window.location.href = "/home";
            });

            var queue = new createjs.LoadQueue(false);
            queue.on("fileload", function(event) {
                self.onLoad(event.result);
            });

            queue.addEventListener("progress", function(event) {
                progressJs().set(event.progress*100);
            });

            queue.loadFile(this.imgSource);
        };

        this.resize = function() {

            var $img = $('.homepage-sc-img img');
            $img.stop().addClass('i_pic_start_disabled');
            var client_width=$(window).width();
            var client_height=$(window).height();

            var coef1=(client_width/$img.attr('orig_w'))*1.2;
            var coef2=(client_height/$img.attr('orig_h'))*1.2;
            if (coef1>coef2) var coef=coef1; else var coef=coef2;
            $img.css({height:$img.attr('orig_h')*coef,width:$img.attr('orig_w')*coef});
            $img.css({top:(client_height-$img.height())/2,left:(client_width-$img.width())/2});

            $img.removeClass('i_pic_start_disabled');
        };


        this.onLoad = function(img) {

            var $img = $(img),
                self=this;

            progressJs().set(100).end();

            $(this.wrapper).addClass('loaded');
            $(this.imageWrapper).append(img);

            $img.attr("orig_h", img.height).attr("orig_w", img.width);


            self.resize();

            $img.show().css({
                    opacity:0
                })
                .animate({
                    opacity:1
                }, 1000);


            window.setTimeout(function()
            {
                $img.removeClass('i_pic_start_over').addClass('i_pic_start_over');

                self.logo_intro_animate=window.setInterval(function(){
                    if (self.logo_intro_animate_state==false) {
                        $img.removeClass('i_pic_start_disabled')
                            .removeClass('i_pic_start_over')
                            .addClass('i_pic_start_over');
                    }
                    else {
                        $img.removeClass('i_pic_start_over').removeClass('i_pic_start_disabled');
                    }
                    self.logo_intro_animate_state=!self.logo_intro_animate_state;
                }, 16000);

            }, 510);

            $(window).on('resize',function() {
                self.resize();
            });
        };


        this.requestFullScreen = function() {

            var el = document.body;

            // Supports most browsers and their versions.
            var requestMethod = el.requestFullScreen || el.webkitRequestFullScreen
                || el.mozRequestFullScreen || el.msRequestFullScreen;

            if (requestMethod) {

                // Native full screen.
                requestMethod.call(el);

            } else if (typeof window.ActiveXObject !== "undefined") {

                // Older IE.
                var wscript = new ActiveXObject("WScript.Shell");

                if (wscript !== null) {
                    wscript.SendKeys("{F11}");
                }
            }
        }

    }

})(jQuery);
